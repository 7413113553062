import {
    Action,
} from 'redux';

import {
    IOrder,
    OrderActions,
} from 'app/types/order';
import {
    IHistory,
} from 'app/types/history';

import {
    applyPatch,
} from 'app/libs/structure';
import {
    mergeEntities,
} from 'app/libs/utils';

interface IData {
    orders: IOrder[];
    history: IHistory[];
}

export type IOrderReducer = {
    _data: IData;
    getOrders: () => IOrder[];
    getOrder: (orderId: number) => IOrder;
    getHistory: () => IHistory[];
    getOrderHistory: (orderId: number) => IHistory[];
};

export interface IOrderAction extends Action<OrderActions> {
    payload?: {
        orders?: IOrder[];
        history?: IHistory[];
    };
}

const initialOrders: [] = [];
const initialHistory: [] = [];

const initialState: IOrderReducer = {
    _data: {
        orders: initialOrders,
        history: initialHistory,
    } as IData,

    getOrders() {
        return this._data.orders;
    },

    getOrder(orderId: number) {
        return (this._data.orders as IOrder[]).find(({id}) => id === orderId) || null;
    },

    getHistory() {
        return this._data.history;
    },

    getOrderHistory(orderId: number) {
        return (this._data.history as IHistory[]).filter(({entityId}) => entityId === orderId);
    },
};

export default function(state = initialState, {payload = {}, type}: IOrderAction): IOrderReducer {
    if (!type) {
        return state;
    }

    const currentState = {...state};
    let patch: any;

    switch (type) {
        case OrderActions.Create:
        case OrderActions.Update:
        case OrderActions.Get:
            patch = {
                orders: {
                    $set: mergeEntities(currentState.getOrders(), payload.orders)
                        .sort((a, b) => b.id - a.id),
                }
            };
            break;

        case OrderActions.Clear:
            patch = {
                orders: {
                    $set: initialOrders,
                }
            };
            break;

        case OrderActions.GetHistory:
            patch = {
                history: {
                    $set: mergeEntities(currentState.getHistory(), payload.history)
                        .sort((a, b) => b.id - a.id),
                }
            };
            break;

        case OrderActions.ClearHistory:
            patch = {
                history: {
                    $set: initialHistory,
                }
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
