import {
    Dispatch,
} from 'redux';

import {
    IUser,
    UserActions,
    IUserGetParams,
} from 'app/types/user';
import {
    IHistoryGetParams,
} from 'app/types/fetch';
import {
    IUserAction,
} from 'app/reducers/user';

import {
    getUsers as DTGetUsers,
    getCurrentUser as DTGetCurrentUser,
    updateUsers as DTUpdateUsers,
    logIn as DTLogIn,
    logOut as DTLogOut,
    signUp as DTSignUp,
    regenerateApiKey as DTRegenerateApiKey,
    getHistory as DTGetHistory,
    getUserCustomization,
} from 'app/dataProviders/user';

export function signUp(data: IUser) {
    return async (dispatch: Dispatch<IUserAction>) => {
        const currentUser = await DTSignUp(data);

        return dispatch({
            type: UserActions.SignUp,
            payload: {
                currentUser,
            },
        });
    };
}

export function logIn(data: IUser) {
    return async (dispatch: Dispatch<IUserAction>) => {
        const currentUser = await DTLogIn(data);
        const result = dispatch({
            type: UserActions.LogIn,
            payload: {
                currentUser,
            },
        });

        try {
            const currentUserCustomization = await getUserCustomization();
            dispatch({
                type: UserActions.SetCurrentUserCustomization,
                payload: {
                    currentUserCustomization,
                }
            });
        } catch (ex) {
            console.error(ex);
        }

        return result;
    };
}

export function logOut() {
    return async (dispatch: Dispatch<IUserAction>) => {
        await DTLogOut();

        return dispatch({
            type: UserActions.LogOut,
        });
    };
}

export function getCurrentUser() {
    return async (dispatch: Dispatch<IUserAction>) => {
        const currentUser = await DTGetCurrentUser();

        const result = dispatch({
            type: UserActions.GetCurrent,
            payload: {
                currentUser,
            }
        });

        try {
            const currentUserCustomization = await getUserCustomization();
            dispatch({
                type: UserActions.SetCurrentUserCustomization,
                payload: {
                    currentUserCustomization,
                }
            });
        } catch (ex) {
            console.error(ex);
        }

        return result;
    };
}

export function getUsers(params?: IUserGetParams) {
    return async (dispatch: Dispatch<IUserAction>) => {
        const users: IUser[] = await DTGetUsers(params);

        if (!users || !users.length) {
            return [];
        }

        dispatch({
            type: UserActions.Get,
            payload: {
                users,
            }
        });

        return users;
    };
}

export function updateUsers(usersToUpdate: IUser[]) {
    return async (dispatch: Dispatch<IUserAction>) => {
        if (!usersToUpdate || !usersToUpdate.length) {
            return;
        }
        const updatedUsers = await DTUpdateUsers(usersToUpdate);

        return dispatch({
            type: UserActions.Update,
            payload: {
                users: updatedUsers,
            },
        });
    };
}

export function clearUsers() {
    return (dispatch: Dispatch<IUserAction>) => {
        return dispatch({
            type: UserActions.Clear,
        });
    };
}


export function regenerateApiKey() {
    return async (dispatch: Dispatch<IUserAction>) => {
        const currentUser = await DTRegenerateApiKey();

        return dispatch({
            type: UserActions.RegenerateApiKey,
            payload: {
                currentUser,
            },
        });
    };
}

export function getUsersHistory(params?: IHistoryGetParams) {
    return async (dispatch: Dispatch<IUserAction>) => {
        const history = await DTGetHistory(params);

        dispatch({
            type: UserActions.GetHistory,
            payload: {
                history,
            }
        });

        return history;
    };
}

export function clearUsersHistory() {
    return (dispatch: Dispatch<IUserAction>) => {
        return dispatch({
            type: UserActions.ClearHistory,
        });
    };
}

export function setVirtualFields(users: IUser[]) {
    return (dispatch: Dispatch<IUserAction>) => {
        return dispatch({
            type: UserActions.SetVirtualFields,
            payload: {
                users,
            }
        });
    };
}
