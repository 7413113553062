import {
    IBaseGetParams,
} from 'app/types/fetch';

import constants from 'app/constants.json';

export interface ICustomPrice {
    id?: number;
    customerUserId?: number;
    attrNameEnum?: AttrName;
    attrValue?: number;
    isRemoved?: boolean;
    createdByUserId?: number;
    createdTimestamp?: string;
}

export enum CustomPricesActions {
    Create = 'custom_prices_create',
    Update = 'custom_prices_update',
    Get = 'custom_prices_get',
    Clear = 'custom_prices_clear',
    GetHistory = 'get_custom_prices_history',
    ClearHistory = 'clear_custom_prices_history',
}

export interface ICustomPricesGetParams extends IBaseGetParams {
    customerUserIds?: number[];
    attrNameEnum?: number[];
}


export enum AttrName {
    'Weight0-3' = constants.pricesAttributeEnum.weightEnum['0-3'],
    'Weight4-6' = constants.pricesAttributeEnum.weightEnum['4-6'],
    'Weight7-9' = constants.pricesAttributeEnum.weightEnum['7-9'],
    'Weight10-12' = constants.pricesAttributeEnum.weightEnum['10-12'],

    deliveryLocationEnumMoscow = constants.pricesAttributeEnum.deliveryLocationEnum.moscow,
    deliveryLocationEnumMKAD = constants.pricesAttributeEnum.deliveryLocationEnum.MKAD,
    deliveryLocationEnumMO = constants.pricesAttributeEnum.deliveryLocationEnum.MO,

    takingLocationEnumMoscow = constants.pricesAttributeEnum.takingLocationEnum.moscow,
    takingLocationEnumMKAD = constants.pricesAttributeEnum.takingLocationEnum.MKAD,
    takingLocationEnumMO = constants.pricesAttributeEnum.takingLocationEnum.MO,

    urgencyEnumEconomy = constants.pricesAttributeEnum.urgencyEnum.economy,
    urgencyEnumPerDay = constants.pricesAttributeEnum.urgencyEnum.perDay,
    urgencyEnumDayToDay = constants.pricesAttributeEnum.urgencyEnum.dayToDay,
    urgencyEnumUrgently = constants.pricesAttributeEnum.urgencyEnum.urgently,

    insuranceLimit = constants.pricesAttributeEnum.insurance.limit,
    insurancePercent = constants.pricesAttributeEnum.insurance.percent,
    insuranceCalculateType = constants.pricesAttributeEnum.insurance.calculateType,

    legalCustomerOperatingExpenses = constants.pricesAttributeEnum.legalCustomerOperatingExpenses,

    baseDeliveryCost = constants.pricesAttributeEnum.baseDeliveryCost,
}

export const attrNameOptions = [
    {
        value: AttrName['Weight0-3'],
        name: 'Вес: 0-3 кг.',
    },
    {
        value: AttrName['Weight4-6'],
        name: 'Вес: 4-6 кг.',
    },
    {
        value: AttrName['Weight7-9'],
        name: 'Вес: 7-9 кг.',
    },
    {
        value: AttrName['Weight10-12'],
        name: 'Вес: 10-12 кг.',
    },

    {
        value: AttrName.deliveryLocationEnumMoscow,
        name: 'Локация доставки: Москва',
    },
    {
        value: AttrName.deliveryLocationEnumMKAD,
        name: 'Локация доставки: МКАД',
    },
    {
        value: AttrName.deliveryLocationEnumMO,
        name: 'Локация доставки: Московская область',
    },

    {
        value: AttrName.takingLocationEnumMoscow,
        name: 'Локация забора: Москва',
    },
    {
        value: AttrName.takingLocationEnumMKAD,
        name: 'Локация забора: МКАД',
    },
    {
        value: AttrName.takingLocationEnumMO,
        name: 'Локация забора: Московская область',
    },

    {
        value: AttrName.urgencyEnumEconomy,
        name: 'Срочность: эконом',
    },
    {
        value: AttrName.urgencyEnumPerDay,
        name: 'Срочность: за день',
    },
    {
        value: AttrName.urgencyEnumDayToDay,
        name: 'Срочность: день в день',
    },
    {
        value: AttrName.urgencyEnumUrgently,
        name: 'Срочность: срочно',
    },

    {
        value: AttrName.insuranceLimit,
        name: 'Страховой лимит',
    },
    {
        value: AttrName.insurancePercent,
        name: 'Страховой процент',
    },
    {
        value: AttrName.insuranceCalculateType,
        name: 'Тип расчета страхового сбора',
    },

    {
        value: AttrName.legalCustomerOperatingExpenses,
        name: 'Операционные расходы юридического лица',
    },

    {
        value: AttrName.baseDeliveryCost,
        name: 'Базовая стоимость доставки',
    },
];
