import {
    createHashHistory,
    // @ts-ignore
} from 'history';

import {
    FrontendUrls,
} from 'app/constants/url';

const history = createHashHistory();

export function pushUrl(url: string) {
    if (getLocation().pathname !== url) {
        history.push(url);
    }
}

export function navigateToPrevUrl() {
    history.goBack();
}

export function getLocation() {
    return history.location;
}

export function navigateToLogIn() {
    return pushUrl(FrontendUrls.LogIn);
}

export default history;
