import {
    IS_DEVELOPMENT,
} from 'app/libs/env';

export enum FrontendUrls {
    LogIn = '/logIn',
    SignUp = '/signUp',
    Orders = '/orders',
    Payments = '/payments',
    CustomPrices = '/customPrices',
    Users = '/users',
    Account = '/account',
    Analytics = '/analytics',
    Help = '/help',
    NotFind = '*',
    Home = '/',
    Api = '/api',
}

export enum BackendUrls {
    UserSignUp = 'user/signUp',
    UserLogIn = 'user/logIn',
    UserLogOut = 'user/logOut',
    UserGet = 'user/get',
    UserMe = 'user/me',
    UserUpdate = 'user/update',
    UserRegenerateApiKey = 'user/regenerateApiKey',
    UserHistory = 'user/history',

    OrderCreate = 'order/create',
    OrderUpdate = 'order/update',
    OrderGet = 'order/get',
    OrderHistory = 'order/history',

    PaymentCreate = 'payment/create',
    PaymentUpdate = 'payment/update',
    PaymentGet = 'payment/get',
    PaymentHistory = 'payment/history',

    CustomPricesCreate = 'customPrices/create',
    CustomPricesUpdate = 'customPrices/update',
    CustomPricesGet = 'customPrices/get',
    CustomPricesHistory = 'customPrices/history',

    FromFrontendReportError = 'frontback/fromFrontend/report/error',

    OrdersParseCsv = 'frontback/orders/parse/csv',
    OrdersDownloadCsv = 'frontback/orders/download',

    CalculationsBalance = 'frontback/calculations/balance',
    CalculationsDebt = 'frontback/calculations/debt',
    StatisticCustomers = 'frontback/statistic/customers',
    StatisticCouriers = 'frontback/statistic/couriers',
    Calculations = 'frontback/calculations',
    OrdersCreateInvoices = 'frontback/orders/invoices',
    OrdersCreateRegisterInvoice = 'frontback/orders/register/invoice',

    GetUserCustomization = 'bot/helper/customization/user',
}

const devServerHostname = `${location.protocol}//test-skd-delivery.ru`;
const serverHostname = `${location.origin}`;

export const serverHttpHost =
    document.location.search.search('local') === -1
        ?
        IS_DEVELOPMENT
            ? devServerHostname
            : serverHostname
        : `${location.protocol}//localhost:7000`;

export const staticBaseUrl = `${serverHttpHost}/static/`;
