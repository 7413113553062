import {
    serverHttpHost,
    BackendUrls,
} from 'app/constants/url';

import {
    IS_DEVELOPMENT,
} from 'app/libs/env';
import fetch from 'app/libs/fetch';

import get from 'lodash-es/get';

export default function reportError(error: Error, extraMessage: string = '') {
    if (IS_DEVELOPMENT) {
        console.log('reportError(not send to server)', {
            extraMessage,
            errorMessage: error.message,
            errorStack: error.stack,
        });
        return;
    }

    let navigator = [];
    try {
        navigator.push(`\`network speed\`: ${get(window.navigator, 'connection.downlink')} Mbps`,);
        navigator.push(`\`network rtt\`: ${get(window.navigator, 'connection.rtt')}`,);
        navigator.push(`\`platform\`: ${get(window.navigator, 'platform')}`,);
        navigator.push(`\`vendor\`: ${get(window.navigator, 'vendor')}`,);
        navigator.push(`\`cookieEnabled\`: ${get(window.navigator, 'cookieEnabled')}`,);
        navigator.push(`\`hardwareConcurrency\`: ${get(window.navigator, 'hardwareConcurrency')}`,);
        navigator.push(`\`deviceMemory\`: ${get(window.navigator, 'deviceMemory')}`,);
    } catch (ex) {
        console.error(ex);
    }

    return fetch({
        baseURL: serverHttpHost,
        method: 'POST',
        url: BackendUrls.FromFrontendReportError,
        body:  {
            extraMessage: [
                extraMessage,
                '**data from navigator obj:**',
                navigator.join('\n'),
            ].join('\n'),
            errorMessage: error.message,
            errorStack: error.stack,
        },
    });
}
