import {
    Action,
} from 'redux';

import {
    IUser,
    UserActions,
} from 'app/types/user';
import {
    IHistory,
} from 'app/types/history';
import {
    INormalizedUserCustomization,
} from 'app/types/userCustomization';

import {
    applyPatch,
} from 'app/libs/structure';
import {
    mergeEntities,
} from 'app/libs/utils';

interface IData {
    currentUser: IUser;
    currentUserCustomization: INormalizedUserCustomization;
    users: IUser[];
    history: IHistory[];
}

export type IUserReducer = {
    _data: IData;
    getCurrentUser: () => IUser;
    getCurrentUserCustomization: () => INormalizedUserCustomization;
    getUser: (id: number) => IUser;
    getUsers: () => IUser[];
    getHistory: () => IHistory[];
    getAllUserIds: () => number[];
};

export interface IUserAction extends Action<UserActions> {
    payload?: {
        currentUser?: IUser;
        currentUserCustomization?: INormalizedUserCustomization;
        users?: IUser[];
        history?: IHistory[];
    };
}

const initialCurrentUser: null = null;
const initialUsers: [] = [];
const initialHistory: [] = [];

const initialState: IUserReducer = {
    _data: {
        currentUser: initialCurrentUser,
        currentUserCustomization: null,
        users: initialUsers,
        history: initialHistory,
    } as IData,

    getCurrentUser() {
        return this._data.currentUser;
    },

    getCurrentUserCustomization() {
        return this._data.currentUserCustomization;
    },

    getUser(id: number | string) {
        return this._data.users.find((user: IUser) => user.id === Number(id));
    },

    getUsers() {
        return this._data.users;
    },

    getHistory() {
        return this._data.history;
    },

    getAllUserIds(): number[] {
        return this._data.users.map((user: IUser) => user.id);
    },
};

export default function(state = initialState, {payload = {}, type}: IUserAction): IUserReducer {
    if (!type) {
        return state;
    }

    const currentState = {...state};
    let patch: any;

    switch (type) {
        case UserActions.SetCurrentUserCustomization:
            patch = {
                currentUserCustomization: {
                    $set: payload.currentUserCustomization,
                },
            };
            break;

        case UserActions.LogIn:
        case UserActions.SignUp:
        case UserActions.GetCurrent:
        case UserActions.RegenerateApiKey:
            patch = {
                currentUser: {
                    $set: payload.currentUser,
                },
                users: {
                    $set: mergeEntities(currentState.getUsers(), [payload.currentUser])
                        .sort((a, b) => b.id - a.id),
                }
            };
            break;

        case UserActions.LogOut:
            patch = {
                currentUser: {
                    $set: initialCurrentUser,
                },
                users: {
                    $set: initialUsers,
                },
            };
            break;

        case UserActions.Update:
        case UserActions.Get:
        case UserActions.SetVirtualFields:
            const usersBefore = currentState.getUsers();
            const newUsers = payload.users;

            patch = {
                users: {
                    $set: mergeEntities(usersBefore, newUsers)
                        .sort((a, b) => b.id - a.id),
                }
            };
            break;

        case UserActions.Clear:
            patch = {
                users: {
                    $set: initialUsers,
                }
            };
            break;

        case UserActions.GetHistory:
            patch = {
                history: {
                    $set: mergeEntities(currentState.getHistory(), payload.history),
                }
            };
            break;

        case UserActions.ClearHistory:
            patch = {
                history: {
                    $set: initialHistory,
                }
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
