import update from 'immutability-helper';

import {
    reduxLoggerPatch,
    reduxLoggerState,
} from 'app/libs/logger';

export function applyPatch(prevState: any, patch: any, type: string) {
    try {
        reduxLoggerPatch(type, patch);
        const newState = update(prevState, patch);
        reduxLoggerState(prevState, newState);
        return newState;
    } catch (ex) {
        console.log(ex);
        return prevState;
    }
}
