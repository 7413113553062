import mergeWith from 'lodash-es/mergeWith';
import isArray from 'lodash-es/isArray';
import transform from 'lodash-es/transform';
import isObject from 'lodash-es/isObject';
import isEqual from 'lodash-es/isEqual';

export function mergeEntities<T extends {id?: number}>(entitiesBefore: T[], newEntities: T[]) {
    // начинаем заполнение нового массива энтити, со старых энтити
    const mergedEntities: T[] = entitiesBefore.map(entityBefore => {
        const newEntity = newEntities.find(({id}) => id === entityBefore.id);
        // новая энтити не меняет старую
        if (!newEntity) {
            return entityBefore;
        }
        // новая энтити меняет старую
        return mergeWith(entityBefore, newEntity, (oldValue, newValue) => {
            if (oldValue && newValue && oldValue.id && newValue.id) {
                return newValue;
            }
            if (isArray(oldValue)) {
                return oldValue.concat(newValue);
            }
        });
    });
    newEntities.forEach(newEntity => {
       if (mergedEntities.findIndex(({id}) => id === newEntity.id) === -1) {
           mergedEntities.push(newEntity);
       }
    });

    return mergedEntities;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 * @link https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
export function difference<T extends object>(object: T, base: T) {
    if (!object || !base) {
        return {};
    }
    const changes = (object: any, base: any) => transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            // @ts-ignore
            result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
        }
    });
    return changes(object, base);
}

export function objectToPaths(object: object): string[] {
    return Object.keys(object).reduce((accum, key) => {
        // @ts-ignore
        const value = object[key];
        if (isObject(value)) {
            return accum.concat(objectToPaths(value).map(nestedKey => `${key}.${nestedKey}`));
        }
        return accum.concat(key);
    }, []);
}
