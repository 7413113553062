import './NotFind.scss';

import {
    navigateToPrevUrl,
} from 'app/libs/history';

import React, {PureComponent} from 'react';

const b = require('b_').with('not-find');

export default class NotFind extends PureComponent {
    render() {
        return (
            <div className={b()} onClick={navigateToPrevUrl}>
                {'Красивая заглушка. Страница не найдена. Кликните чтобы вернуться.'}
            </div>
        ) ;
    }
}
