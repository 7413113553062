import './Alerts.scss';

import {
    Dispatch,
} from 'redux';

import {
    IMapStateToProps,
    IDispatchToProps,
    IProps,
    IState,
} from './Alerts.types';
import {
    AlertState,
} from 'app/types/alerts';
import {
    IReduxState,
} from 'app/reducers';
import {
    IAlertAction,
} from 'app/reducers/alerts';

import React, {
    Component,
} from 'react';
import {
    connect,
} from 'react-redux';

import {
    removeAlert as actionRemoveAlert,
} from 'app/actionCreators/alerts';

import {
    defaultTimeoutToHide,
} from 'app/constants/alerts';

import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import ToastBody from 'react-bootstrap/ToastBody';

const b = require('b_').with('alerts');

class Alerts extends Component<IProps, IState> {
    render() {
        const {
            alerts,
        } = this.props;

        return (
            <div className={b()}>
                {alerts.getAlerts().map(alert => {
                    const {
                        id,
                        state,
                        header,
                        text,
                        children,
                        timeoutToHide,
                        autohide,
                    } = alert;

                    const toastProps: any = {
                        animation: true,
                        autohide: typeof autohide === 'boolean' ? autohide : state !== AlertState.Error,
                        delay: timeoutToHide || defaultTimeoutToHide,
                        onClose: () => this.props.dispatchRemoveAlert(id),
                    };

                    return (
                        <div className={b('alert', {[`type-${state}`]: true})} key={id}>
                            <Toast {...toastProps}>
                                <ToastHeader>
                                    <strong>
                                        {header}
                                    </strong>
                                </ToastHeader>
                                <ToastBody>
                                    {text && text}
                                    {children && children}
                                </ToastBody>
                            </Toast>
                        </div>
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps(state: IReduxState): IMapStateToProps {
    return {
        alerts: state.alerts,
    };
}

function mapDispatchToProps(dispatch: Dispatch<IAlertAction>): IDispatchToProps {
    return {
        dispatchRemoveAlert: (id: string) => actionRemoveAlert(id)(dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Alerts);
