import {
    IFetchOptions,
} from 'app/types/fetch';

import axios, {
    AxiosError,
} from 'axios';
import qs from 'qs';
import isEmpty from 'lodash-es/isEmpty';
import isArray from 'lodash-es/isArray';
import isPlainObject from 'lodash-es/isPlainObject';

import {
    BackendUrls,
    FrontendUrls,
    serverHttpHost,
} from 'app/constants/url';

import {
    pushUrl,
} from 'app/libs/history';
import {
    fetchLoggerProcess,
    fetchLoggerSuccess,
    fetchLoggerFailed,
} from 'app/libs/logger';
import reportError from 'app/libs/reportError';

const DEFAULT_RETRIES = 120;
const NETWORK_ERROR_TIMEOUT = 3 * 1000;

const axiosInstance = axios.create({
    withCredentials: true,
});

// user?ids[]=1&ids[]=2 => user?ids=1&ids=2
axios.defaults.paramsSerializer = params => qs.stringify(params, {
    indices: false,
});

export default function fetch<T>(options: IFetchOptions): Promise<T> {
    fetchLoggerProcess(options);

    const wrapFetch = async (options: IFetchOptions, retries: number = 0): Promise<any> => {
        try {
            if (retries < 0) {
                throw new Error('retries < 0');
            }
            retries--;

            const {
                method,
                url,
                body,
                params,
                timeout = 120000,
                baseURL = !url.includes('frontback') && !url.includes('bot') ? `${serverHttpHost}/api` : serverHttpHost,
                ...optionsPart
            } = options;

            const response = await axiosInstance.request({
                baseURL,
                url,
                data: body,
                method,
                params,
                timeout,
                ...optionsPart,
            });
            if (
                (isPlainObject(response.data) && isEmpty(response.data))
                || isArray(response.data) && response.data.length !== 0 && response.data.every(isPlainObject) && response.data.every(isEmpty)
            ) {
                throw {
                    response: {
                        data: 'ERROR access rights!',
                    }
                };
            }
            fetchLoggerSuccess(options, response);

            return response.data;
        } catch (error) {
            if (error.message === 'Network Error') {
                await new Promise(resolve => setTimeout(resolve, NETWORK_ERROR_TIMEOUT));
                return wrapFetch(options, retries);
            }
            if (error.code === 'ECONNABORTED') {
                reportError(error, constructExtraMessage({
                    ...error,
                    response: error.response || {},
                }));
                throw new Error(`Запрос отменен: ${error.message.replace(/timeout of (\d+ms) exceeded/, 'истек таймаут запроса в $1. Но он всё равно выполнился, проверьте.')}`);
            }
            if (error.response && error.response.status === 504) {
                reportError(error, constructExtraMessage(error));
                throw new Error('Истекло время ожидания сервера. Попробуйте повторить запрос.');
            }
            fetchLoggerFailed(options, error);

            if ([401, 403, 405].includes(error.response.status) && !error.config.url.includes(BackendUrls.UserLogOut)) {
                pushUrl(FrontendUrls.LogIn);
                throw new Error('Необходимо авторизоваться в системе.');
            }

            if (error.response.status === 403 && error.config.url.includes(BackendUrls.UserLogOut)) {
                return;
            }

            if (!error.config.url.includes(BackendUrls.FromFrontendReportError)) {
                reportError(error, constructExtraMessage(error));
            }

            throw error;
        }
    };

    return wrapFetch(options, DEFAULT_RETRIES);
}

function constructExtraMessage(error: AxiosError) {
    try {
        const {
            config: {
                method,
                url,
                data: requestData = {},
                params = '',
                headers: requestHeaders = {},
                timeout,
            },
            response: {
                status,
                statusText,
                data: responseData = {},
                headers: responseHeaders = {},
            },
            code,
        } = error;

        return [
            `**[${method}] ${url}**`,
            `\`status\`: ${status} ${statusText}`,
            `\`code\`: ${code}`,
            `\`axios timeout\`: ${timeout}`,
            `requestData: ${JSON.stringify(requestData, null, 2)}`,
            `params: ${JSON.stringify(params, null, 2)}`,
            `responseData: ${JSON.stringify(responseData, null, 2)}`,
            `requestHeaders: ${JSON.stringify(requestHeaders, null, 2)}`,
            `responseHeaders: ${JSON.stringify(responseHeaders, null, 2)}`,
        ].join('\n');
    } catch (ex) {
        console.error(ex);
        return 'fetch';
    }
}
