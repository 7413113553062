enum Environments {
    Production = 'production',
    Sandbox = 'sandbox',
    Beta = 'beta',
    Testing = 'testing',
    Development = 'development',
}

const {
    NODE_ENV = Environments.Production,
    APP_ENV = Environments.Production,
} = process.env;

export const IS_PRODUCTION = APP_ENV === Environments.Production;
export const IS_DEVELOPMENT = APP_ENV === Environments.Development;
export const IS_TESTING = APP_ENV === Environments.Testing;
export const IS_SANDBOX = APP_ENV === Environments.Sandbox;

