import 'app/libs/unhandledErrors';

import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.css';
import './app.scss';

import React from 'react';
import {
    render,
} from 'react-dom';
import {
    Provider,
} from 'react-redux';
import {
    Route,
    Switch,
} from 'react-router-dom';
import {
    ConnectedRouter,
    routerMiddleware,
} from 'connected-react-router';
import {
    applyMiddleware,
    createStore,
} from 'redux';
import thunk from 'redux-thunk';

import {
    getCurrentUser,
} from 'app/actionCreators/user';

import {
    FrontendUrls,
} from 'app/constants/url';

import history from 'app/libs/history';

import reducers from 'app/reducers';

import Loading from 'app/components/Loading/Loading';
import Alerts from 'app/components/Alerts/Alerts';
import InternetConnectionStatus from 'app/components/InternetConnectionStatus/InternetConnectionStatus';

import Api from 'app/controllers/Api/Api.loadable';
import Home from 'app/controllers/Home/Home.loadable';
import LogIn from 'app/controllers/LogIn/LogIn.loadable';
import NotFind from 'app/controllers/NotFind/NotFind';
import SignUp from 'app/controllers/SignUp/SignUp.loadable';
import Orders from 'app/controllers/Orders/Orders.loadable';
import Payments from 'app/controllers/Payments/Payments.loadable';
import CustomPrices from 'app/controllers/CustomPrices/CustomPrices.loadable';
import Users from 'app/controllers/Users/Users.loadable';
import Analytics from 'app/controllers/Analytics/Analytics.loadable';
import Account from 'app/controllers/Account/Account.loadable';
import Help from 'app/controllers/Help/Help.loadable';

const middleware = routerMiddleware(history);
const store = createStore(reducers(history), applyMiddleware(thunk, middleware));

const mount = document.getElementById('mount');
const alerts = document.getElementById('alerts');
const internetConnectionStatus = document.getElementById('internetConnectionStatus');

// @ts-ignore
window.store = store;

render(<Loading/>, mount, async () => {
    try {
        await getCurrentUser()(store.dispatch);
    } catch (ex) {

    }

    render((
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div>
                    <Switch>
                        <Route exact path={FrontendUrls.Home} component={Home}/>
                        <Route path={FrontendUrls.Api} component={Api}/>
                        <Route path={FrontendUrls.LogIn} component={LogIn}/>
                        <Route path={FrontendUrls.SignUp} component={SignUp}/>
                        <Route path={FrontendUrls.Orders} component={Orders}/>
                        <Route path={FrontendUrls.Payments} component={Payments}/>
                        <Route path={FrontendUrls.CustomPrices} component={CustomPrices}/>
                        <Route path={FrontendUrls.Users} component={Users}/>
                        <Route path={FrontendUrls.Account} component={Account}/>
                        <Route path={FrontendUrls.Analytics} component={Analytics}/>
                        <Route path={FrontendUrls.Help} component={Help}/>
                        <Route path={FrontendUrls.NotFind} component={NotFind}/>
                    </Switch>
                </div>
            </ConnectedRouter>
        </Provider>
    ), mount);
});

render((
    <Provider store={store}>
        <Alerts/>
    </Provider>
), alerts);

render((
    <InternetConnectionStatus/>
), internetConnectionStatus);
