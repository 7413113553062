import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'app/components/Loading/Loading';

// @ts-ignore
export default Loadable({
    loader: () => {
        // for scrollIntoView in componentDidMount css must load first
        require('app/controllers/Help/Help.scss');
        return import(/*webpackChunkName: 'Help'*/'app/controllers/Help/Help')
            .catch(() => new Error('An error occurred while loading the Help'))
    },
    loading: Loading,
});
