import {
    IBaseGetParams,
} from 'app/types/fetch';

import constants from 'app/constants.json';

export interface IDeliveryTakingData {
    locationEnum: Location;
    subway: string;
    address: string;
    from: string;
    to: string;
}

export interface IContact {
    name?: string;
    phone?: number;
}

export enum Location {
    Moscow = constants.locationEnum.moscow,
    MKAD = constants.locationEnum.MKAD,
    MO = constants.locationEnum.MO,
}

export const locationOptions = [
    {
        value: Location.Moscow,
        name: 'Москва',
    },
    {
        value: Location.MKAD,
        name: 'Метро за МКАД',
    },
    {
        value: Location.MO,
        name: 'М.О.',
    },
];

export enum Weight {
    '0-3' = constants.weightEnum['0-3'],
    '4-6' = constants.weightEnum['4-6'],
    '7-9' = constants.weightEnum['7-9'],
    '10-12' = constants.weightEnum['10-12'],
}

export const weightOptions = [
    {
        value: Weight['0-3'],
        name: 'До 3 кг.',
    },
    {
        value: Weight['4-6'],
        name: '4-6 кг.',
    },
    {
        value: Weight['7-9'],
        name: '7-9 кг.',
    },
    {
        value: Weight['10-12'],
        name: '10-12 кг.',
    },
];

export enum OrderType {
    Delivery = constants.orderTypeEnum.delivery,
    SDEK = constants.orderTypeEnum.SDEK,
    Mail = constants.orderTypeEnum.mail,
    Pickup = constants.orderTypeEnum.pickup,
}

export const orderTypeOptions = [
    {
        value: OrderType.Delivery,
        name: 'Доставка',
    },
    {
        value: OrderType.SDEK,
        name: 'СДЕК',
    },
    {
        value: OrderType.Mail,
        name: 'Почта',
    },
    {
        value: OrderType.Pickup,
        name: 'Самовывоз',
    },
];

export enum PaymentMethod {
    Cash = constants.paymentMethod.cash,
    NonCash = constants.paymentMethod.nonCash,
}

export const paymentMethodOptions = [
    {
        value: PaymentMethod.Cash,
        name: 'Наличные',
    },
    {
        value: PaymentMethod.NonCash,
        name: 'Перевод',
    },
];

export enum OrderStatus {
    OnTheWay = constants.statusesOfOrderEnum.onTheWay,
    DeliveredRedemption = constants.statusesOfOrderEnum.deliveredRedemption,
    DeliveredPartialBuyback = constants.statusesOfOrderEnum.deliveredPartialBuyback,
    CancelNotIssued = constants.statusesOfOrderEnum.cancelNotIssued,
    UnderCall = constants.statusesOfOrderEnum.underCall,
    UnderCallOnPlace = constants.statusesOfOrderEnum.underCallOnPlace,
    FailureByPhone = constants.statusesOfOrderEnum.failureByPhone,
    FailureOnThePlace = constants.statusesOfOrderEnum.failureOnThePlace,
    FakeCall = constants.statusesOfOrderEnum.fakeCall,
    Transferred = constants.statusesOfOrderEnum.transferred,
    Accepted = constants.statusesOfOrderEnum.accepted,
    Initial = constants.statusesOfOrderEnum.initial,
}

export const orderStatusOptions = [
    {
        value: OrderStatus.OnTheWay,
        name: 'В пути',
    },
    {
        value: OrderStatus.DeliveredRedemption,
        name: 'Доставлено/Выкуп',
    },
    {
        value: OrderStatus.DeliveredPartialBuyback,
        name: 'Доставлено/Частичный выкуп',
    },
    {
        value: OrderStatus.CancelNotIssued,
        name: 'Отмена/Не выдано',
    },
    {
        value: OrderStatus.UnderCall,
        name: 'Недозвон',
    },
    {
        value: OrderStatus.UnderCallOnPlace,
        name: 'Недозвон на месте',
    },
    {
        value: OrderStatus.FailureByPhone,
        name: 'Отказ по телефону',
    },
    {
        value: OrderStatus.FailureOnThePlace,
        name: 'Отказ на месте',
    },
    {
        value: OrderStatus.FakeCall,
        name: 'Ложный вызов',
    },
    {
        value: OrderStatus.Transferred,
        name: 'Перенесён',
    },
    {
        value: OrderStatus.Accepted,
        name: 'Принят',
    },
    {
        value: OrderStatus.Initial,
        name: 'Ожидает подтверждения',
    },
];

export interface IOrder {
    id?: number;
    externalId?: string;
    customerUserId?: number;
    courierUserId?: number;
    statusEnum?: OrderStatus;
    weightEnum?: Weight;
    typeEnum?: OrderType;
    paymentMethodEnum?: PaymentMethod;
    simpleProduct?: string;
    taking?: IDeliveryTakingData;
    sender?: IContact;
    delivery?: IDeliveryTakingData;
    client?: IContact;
    cashOnDelivery?: number;
    announcedValue?: number;
    customerComment?: string;
    courierComment?: string;
    priority?: number;
    deliveryCost?: number;
    paymentToCourier?: number;
    insuranceFee?: number;
    shouldAutoUpdateDeliveryCost?: boolean;
    shouldAutoUpdatePaymentToCourier?: boolean;
    shouldAutoUpdateInsuranceFee?: boolean;
    isRemoved?: boolean;
    createdByUserId?: number;
    createdTimestamp?: string;
    closingTimestamp?: string;
    virtual?: {
        income?: number;
    },
}

export enum OrderActions {
    Create = 'order_create',
    Update = 'order_update',
    Get = 'order_get',
    Clear = 'order_clear',
    GetHistory = 'get_order_history',
    ClearHistory = 'clear_order_history',
    UploadCsvFile = 'order_upload_csv_file',
    DownloadCsvFile = 'order_download_csv_file',
    OrdersCreateInvoices = 'orders_create_invoices',
    OrdersCreateRegisterInvoice = 'orders_create_register_invoice',
}

export interface IOrderGetParams extends IBaseGetParams {
    externalIds?: string[];
    customerUserIds?: number[];
    courierUserIds?: number[];
    statusesEnum?: OrderStatus[];
    typesEnum?: OrderType[];
    isCourierNull?: boolean;
    isClosingTimestampNull?: boolean;
    deliveryFrom?: string;
    deliveryTo?: string;
    takingFrom?: string;
    takingTo?: string;
    closingTimestampFrom?: string;
    closingTimestampTo?: string;
    closingDays?: string[];
    deliveryToFrom?: string;
    deliveryToTo?: string;
    deliveryToDays?: string[];
    virtual?: {
    },
}
