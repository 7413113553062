import {
    IUser,
    IUserGetParams,
} from 'app/types/user';
import {
    INormalizedUserCustomization,
} from 'app/types/userCustomization';
import {
    IHistoryGetParams,
} from 'app/types/fetch';
import {
    IHistory,
} from 'app/types/history';

import {
    BackendUrls,
} from 'app/constants/url';

import fetch from 'app/libs/fetch';

export function signUp(body: IUser) {
    return fetch<IUser>({
        method: 'POST',
        url: BackendUrls.UserSignUp,
        body,
    });
}

export function logIn(body: IUser) {
    return fetch<IUser>({
        method: 'POST',
        url: BackendUrls.UserLogIn,
        body,
    });
}

export function logOut() {
    return fetch({
        method: 'POST',
        url: BackendUrls.UserLogOut,
        body: {},
    });
}

export function getCurrentUser() {
    return fetch<IUser>({
        method: 'GET',
        url: BackendUrls.UserMe,
    });
}

export function getUserCustomization() {
    return fetch<INormalizedUserCustomization>({
        method: 'GET',
        url: BackendUrls.GetUserCustomization,
    });
}


export function getUsers(params?: IUserGetParams) {
    return fetch<IUser[]>({
        method: 'GET',
        url: BackendUrls.UserGet,
        params,
    });
}

export function updateUsers(usersToUpdate: IUser[]) {
    return fetch<IUser[]>({
        method: 'PATCH',
        url: BackendUrls.UserUpdate,
        body: usersToUpdate,
    });
}

export function regenerateApiKey() {
    return fetch<IUser>({
        method: 'PATCH',
        url: BackendUrls.UserRegenerateApiKey,
    });
}

export function getHistory(params?: IHistoryGetParams) {
    return fetch<IHistory[]>({
        method: 'GET',
        url: BackendUrls.UserHistory,
        params,
    });
}
