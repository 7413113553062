import {
    Action,
} from 'redux';

import {
    AlertActions,
    IAlert,
} from 'app/types/alerts';

import {
    applyPatch,
} from 'app/libs/structure';

interface IData {
    alerts: IAlert[];
}

export type IAlertReducer = {
    _data: IData;
    getAlerts: () => IAlert[];
};

export interface IAlertAction extends Action<AlertActions> {
    payload?: {
        alert?: IAlert;
        id?: string;
    };
}

const initialAlerts: IAlert[] = [];

const initialState: IAlertReducer = {
    _data: {
        alerts: initialAlerts,
    } as IData,

    getAlerts() {
        return this._data.alerts;
    },
};

export default function(state = initialState, {payload = {}, type}: IAlertAction): IAlertReducer {
    if (!type) {
        return state;
    }

    const currentState = {...state};
    let patch: any;

    switch (type) {
        case AlertActions.Add:
            patch = {
                alerts: {
                    $set: currentState.getAlerts().concat(payload.alert),
                }
            };
            break;

        case AlertActions.Remove:
            patch = {
                alerts: {
                    $set: currentState.getAlerts().filter(({id}) => id !== payload.id),
                }
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
