import {
    Action,
} from 'redux';

import {
    ICustomPrice,
    CustomPricesActions,
} from 'app/types/customPrices';
import {
    IHistory,
} from 'app/types/history';

import {
    applyPatch,
} from 'app/libs/structure';
import {
    mergeEntities,
} from 'app/libs/utils';

interface IData {
    customPrices: ICustomPrice[];
    history: IHistory[];
}

export type ICustomPricesReducer = {
    _data: IData;
    getCustomPrices: () => ICustomPrice[];
    getHistory: () => IHistory[];
};

export interface ICustomPricesAction extends Action<CustomPricesActions> {
    payload?: {
        customPrices?: ICustomPrice[];
        history?: IHistory[];
    };
}

const initialCustomPrices: [] = [];
const initialHistory: [] = [];

const initialState: ICustomPricesReducer = {
    _data: {
        customPrices: initialCustomPrices,
        history: initialHistory,
    } as IData,

    getCustomPrices() {
        return this._data.customPrices;
    },

    getHistory() {
        return this._data.history;
    },
};

export default function(state = initialState, {payload = {}, type}: ICustomPricesAction): ICustomPricesReducer {
    if (!type) {
        return state;
    }

    const currentState = {...state};
    let patch: any;

    switch (type) {
        case CustomPricesActions.Create:
        case CustomPricesActions.Update:
        case CustomPricesActions.Get:
            patch = {
                customPrices: {
                    $set: mergeEntities(currentState.getCustomPrices(), payload.customPrices)
                        .sort((a, b) => b.id - a.id),
                }
            };
            break;

        case CustomPricesActions.Clear:
            patch = {
                customPrices: {
                    $set: initialCustomPrices,
                }
            };
            break;

        case CustomPricesActions.GetHistory:
            patch = {
                history: {
                    $set: mergeEntities(currentState.getHistory(), payload.history),
                }
            };
            break;

        case CustomPricesActions.ClearHistory:
            patch = {
                history: {
                    $set: initialHistory,
                }
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
