import {
    OrderStatus,
    OrderType,
} from 'app/types/order';
import {
    PaymentType,
} from 'app/types/payment';

export interface IBalanceQueryRequest {
    dayTo?: string;
    dayFrom?: string;
}

export interface IDebtQueryRequest {
    dayTo?: string;
    dayFrom?: string;
}

export interface IBalanceResponse {
    day: string;
    orders: {
        closed: {
            count: number;
            totalByType: Record<OrderType, {
                totalDeliveryCost: number;
                totalInsuranceFee: number;
                totalPaymentToCourier: number;
                totalCashOnDelivery: number;
            }>;
        };
        opening: {
            count: number;
            totalByType: Record<OrderType, {
                totalDeliveryCost: number;
                totalInsuranceFee: number;
                totalPaymentToCourier: number;
                totalCashOnDelivery: number;
            }>;
        };
    };
    payments: {
        totalByType: Record<PaymentType, number>;
    }
}

export interface IDebtResponse {
    customers: IDebt[];
    couriers: IDebt[];
}

interface IDebt {
    userId: number;
    accountsPayable: number;
    accountsReceivable: number;
    balance: number;
}

export interface IDeliveredBeforeOrAfterOnTime {
    orderId: number;
    diffMs: number;
}

export interface ICustomerStatisticResponse {
    id: number;
    closingOrdersCount: number;
    openingOrdersCount: number;
    totalInsuranceFee: number;
    incomeByOrderType: Record<OrderType, number>;
    ordersCountByOrderStatus: Record<OrderStatus, number>;
    notDeliveredOnTimeOrdersIds: number[];
    deliveredBeforeOnTimeOrdersIds: IDeliveredBeforeOrAfterOnTime[];
    deliveredAfterOnTimeOrdersIds: IDeliveredBeforeOrAfterOnTime[];
}

export interface ICourierStatisticResponse {
    id: number;
    closingOrdersCount: number;
    openingOrdersCount: number;
    totalIncome: number;
    totalPaymentToCourier: number;
    ordersCountByOrderStatus: Record<OrderStatus, number>;
    notDeliveredOnTimeOrdersIds: number[];
    deliveredBeforeOnTimeOrdersIds: IDeliveredBeforeOrAfterOnTime[];
    deliveredAfterOnTimeOrdersIds: IDeliveredBeforeOrAfterOnTime[];
}

export enum StatisticActions {
    BalanceGet = 'statistic_balance_get',
    DebtGet = 'statistic_debt_get',
    CustomersGet = 'statistic_customers_get',
    CouriersGet = 'statistic_couriers_get',
}
