import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'app/components/Loading/Loading';

// @ts-ignore
export default Loadable({
    loader: () => import(/*webpackChunkName: 'Account'*/'app/controllers/Account/Account')
        .catch(() => new Error('An error occurred while loading the Account')),
    loading: Loading,
});
