import {
    Action,
} from 'redux';
import {
    IBalanceResponse,
    IDebtResponse,
    ICustomerStatisticResponse,
    ICourierStatisticResponse,
    IDeliveredBeforeOrAfterOnTime,

    StatisticActions,
} from 'app/types/analytics';
import {
    PaymentType,
} from 'app/types/payment';
import {
    OrderStatus,
    OrderType,
} from 'app/types/order';

import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';

import {
    applyPatch,
} from 'app/libs/structure';

interface IClientBalanceFormat {
    day: string;
    openingOrdersCount: number;
    closedOrdersCount: number;
    deliveryIncome: number;
    pickupIncome: number;
    fulfilmentIncome: number;
    totalInsuranceFee: number;
    estimatedProfit: number;
    factualProfit: number;
}

interface IClientDebtFormat {
    userId: number;
    accountsPayable: number;
    accountsReceivable: number;
    inverseBalance: number;
}

export interface IPercentInfo {
    count: number;
    percent: number;
}

export interface OrdersDeliveredTimeDetail {
    upTo15Min: IPercentInfo;
    upTo30Min: IPercentInfo;
    over30Minutes: IPercentInfo;
}

export type OrdersStatusesPercents = Record<Partial<OrderStatus>, IPercentInfo>;

export interface IBaseClientStatistic {
    closedOrdersCount: number;
    openingOrdersCount: number;

    ordersStatusesPercents: OrdersStatusesPercents;
    ordersDeliveredOnTimePercents: IPercentInfo;
    ordersDeliveredBeforeOnTimePercents: IPercentInfo;
    ordersDeliveredAfterOnTimePercents: IPercentInfo;
    ordersDeliveredBeforeOnTimeDetail: OrdersDeliveredTimeDetail;
    ordersDeliveredAfterOnTimeDetail: OrdersDeliveredTimeDetail;
}

interface IClientCustomerStatistic extends IBaseClientStatistic {
    userId: number;
    income: number;

    incomeDelivery: number;
    incomePickup: number;
    incomeFulfilment: number;
    insuranceFeeTotal: number;
}

interface IClientCourierStatistic extends IBaseClientStatistic {
    userId: number;
    income: number;
    courierSalary: number;
}

interface IData {
    balance: IBalanceResponse[];
    debt: IDebtResponse;
    customers: ICustomerStatisticResponse[];
    couriers: ICourierStatisticResponse[];
}

export type IStatisticReducer = {
    _data: IData;

    getBalance: () => IBalanceResponse[];
    getDebt: () => IDebtResponse;
    getCustomers: () => ICustomerStatisticResponse[];
    getCouriers: () => ICourierStatisticResponse[];
    getClientBalanceFormat(): IClientBalanceFormat[];
    getClientCustomersDebtFormat(): IClientDebtFormat[];
    getClientCouriersDebtFormat(): IClientDebtFormat[];
    getClientCustomersStatisticFormat(): IClientCustomerStatistic[];
    getClientCouriersStatisticFormat(): IClientCourierStatistic[];
    getAllUserIds(): number[];
    _calculateOrdersStatusesPercents(ordersCountByOrderStatus: Record<OrderStatus, number>, closingOrdersCount: number): OrdersStatusesPercents;
    _calculateOrdersDeliveredOnTimePercents(data: IDeliveredBeforeOrAfterOnTime[], closingOrdersCount: number): IPercentInfo;
    _calculateOrdersNotDeliveredOnTimePercents(data: IDeliveredBeforeOrAfterOnTime[], closingOrdersCount: number): IPercentInfo;
    _calculateOrdersDeliveredTimeDetailPercents(data: IDeliveredBeforeOrAfterOnTime[], closingOrdersCount: number): OrdersDeliveredTimeDetail;
};

export interface IStatisticAction extends Action<StatisticActions> {
    payload?: {
        balance?: IBalanceResponse[];
        debt?: IDebtResponse;
        customers?: ICustomerStatisticResponse[];
        couriers?: ICourierStatisticResponse[];
    };
}

const MINUTE_MS = 1000 * 60;

const initialState: IStatisticReducer = {
    _data: {
        balance: [],
        debt: {
            customers: [],
            couriers: [],
        },
        customers: [],
        couriers: [],
    } as IData,

    getBalance(): IBalanceResponse[] {
        return this._data.balance;
    },

    getDebt(): IDebtResponse {
        return this._data.debt;
    },

    getCustomers(): ICustomerStatisticResponse[] {
        return this._data.customers;
    },

    getCouriers(): ICourierStatisticResponse[] {
        return this._data.couriers;
    },

    getClientBalanceFormat(): IClientBalanceFormat[] {
        return (this.getBalance() as IBalanceResponse[]).map(dayBalance => {
            const {
                day,
                orders: {
                    closed: {
                        count: closedOrdersCount = 0,
                        totalByType: closedOrdersMetaTotalByType = {},
                    } = {},
                    opening: {
                        count: openingOrdersCount = 0,
                    } = {},
                } = {},
                payments: {
                    totalByType: paymentsTotalByType = {},
                } = {},
            } = dayBalance;

            const deliveryIncome = get(closedOrdersMetaTotalByType[OrderType.Delivery], 'totalDeliveryCost', 0) - get(closedOrdersMetaTotalByType[OrderType.Delivery], 'totalPaymentToCourier', 0);
            const pickupIncome = get(closedOrdersMetaTotalByType[OrderType.Pickup], 'totalDeliveryCost', 0) - get(closedOrdersMetaTotalByType[OrderType.Pickup], 'totalPaymentToCourier', 0);
            const fulfilmentIncome = (
                (get(closedOrdersMetaTotalByType[OrderType.SDEK], 'totalDeliveryCost', 0) - get(closedOrdersMetaTotalByType[OrderType.SDEK], 'totalPaymentToCourier', 0))
                +
                (get(closedOrdersMetaTotalByType[OrderType.Mail], 'totalDeliveryCost', 0) - get(closedOrdersMetaTotalByType[OrderType.Mail], 'totalPaymentToCourier', 0))
            );
            const totalIncome = deliveryIncome + pickupIncome + fulfilmentIncome;
            const totalInsuranceFee = Object.values(closedOrdersMetaTotalByType)
                .reduce((accum, {totalInsuranceFee}) => accum + totalInsuranceFee, 0);
            return {
                day,
                openingOrdersCount,
                closedOrdersCount,
                deliveryIncome,
                pickupIncome,
                fulfilmentIncome,
                totalInsuranceFee,
                estimatedProfit: totalIncome + totalInsuranceFee,
                factualProfit: (paymentsTotalByType[PaymentType.CourierIncome] || 0)
                    - (paymentsTotalByType[PaymentType.CourierExpenditure] || 0)
                    - (paymentsTotalByType[PaymentType.CustomerCalculations] || 0),
            };
        });
    },

    getClientCustomersDebtFormat(): IClientDebtFormat[] {
        return (this.getDebt() as IDebtResponse).customers.map(debt => {
            const {
                userId,
                accountsReceivable,
                accountsPayable,
                balance,
            } = debt;

            return {
                userId,
                accountsReceivable,
                accountsPayable,
                inverseBalance: -balance,
            };
        });
    },

    getClientCouriersDebtFormat(): IClientDebtFormat[] {
        return (this.getDebt() as IDebtResponse).couriers.map(debt => {
            const {
                userId,
                accountsReceivable,
                accountsPayable,
                balance,
            } = debt;

            return {
                userId,
                accountsReceivable,
                accountsPayable,
                inverseBalance: -balance,
            };
        });
    },

    getClientCustomersStatisticFormat(): IClientCustomerStatistic[] {
        return (this.getCustomers() as ICustomerStatisticResponse[]).map(customerStatistic => {
            const {
                id,
                closingOrdersCount,
                openingOrdersCount,
                totalInsuranceFee,
                incomeByOrderType,
                ordersCountByOrderStatus,
                notDeliveredOnTimeOrdersIds,
                deliveredBeforeOnTimeOrdersIds,
                deliveredAfterOnTimeOrdersIds,
            } = customerStatistic;

            const incomeDelivery = incomeByOrderType[OrderType.Delivery] || 0;
            const incomePickup = incomeByOrderType[OrderType.Pickup] || 0;
            const incomeFulfilment = (
                (incomeByOrderType[OrderType.SDEK] || 0)
                +
                (incomeByOrderType[OrderType.Mail] || 0)
            );

            return {
                userId: id,
                income: incomeDelivery + incomePickup + incomeFulfilment + totalInsuranceFee,
                closedOrdersCount: closingOrdersCount,
                openingOrdersCount,
                incomeDelivery,
                incomePickup,
                incomeFulfilment,
                insuranceFeeTotal: totalInsuranceFee,
                ordersStatusesPercents: this._calculateOrdersStatusesPercents(ordersCountByOrderStatus, closingOrdersCount),
                ordersDeliveredOnTimePercents: this._calculateOrdersDeliveredOnTimePercents(notDeliveredOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredBeforeOnTimePercents: this._calculateOrdersNotDeliveredOnTimePercents(deliveredBeforeOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredAfterOnTimePercents: this._calculateOrdersNotDeliveredOnTimePercents(deliveredAfterOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredBeforeOnTimeDetail: this._calculateOrdersDeliveredTimeDetailPercents(deliveredBeforeOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredAfterOnTimeDetail: this._calculateOrdersDeliveredTimeDetailPercents(deliveredAfterOnTimeOrdersIds, closingOrdersCount),
            };
        });
    },

    getClientCouriersStatisticFormat(): IClientCourierStatistic[] {
        return (this.getCouriers() as ICourierStatisticResponse[]).map(courierStatistic => {
            const {
                id,
                totalIncome,
                totalPaymentToCourier,
                closingOrdersCount,
                openingOrdersCount,
                ordersCountByOrderStatus,
                notDeliveredOnTimeOrdersIds,
                deliveredBeforeOnTimeOrdersIds,
                deliveredAfterOnTimeOrdersIds,
            } = courierStatistic;

            return {
                userId: id,
                income: totalIncome,
                courierSalary: totalPaymentToCourier,
                closedOrdersCount: closingOrdersCount,
                openingOrdersCount,
                ordersStatusesPercents: this._calculateOrdersStatusesPercents(ordersCountByOrderStatus, closingOrdersCount),
                ordersDeliveredOnTimePercents: this._calculateOrdersDeliveredOnTimePercents(notDeliveredOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredBeforeOnTimePercents: this._calculateOrdersNotDeliveredOnTimePercents(deliveredBeforeOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredAfterOnTimePercents: this._calculateOrdersNotDeliveredOnTimePercents(deliveredAfterOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredBeforeOnTimeDetail: this._calculateOrdersDeliveredTimeDetailPercents(deliveredBeforeOnTimeOrdersIds, closingOrdersCount),
                ordersDeliveredAfterOnTimeDetail: this._calculateOrdersDeliveredTimeDetailPercents(deliveredAfterOnTimeOrdersIds, closingOrdersCount),
            };
        });
    },

    getAllUserIds(): number[] {
        return uniq(
            []
                .concat((this.getDebt() as IDebtResponse).customers.map(({userId}) => userId))
                .concat((this.getDebt() as IDebtResponse).couriers.map(({userId}) => userId))
                .concat((this.getCustomers() as ICustomerStatisticResponse[]).map(({id}) => id))
                .concat((this.getCouriers() as ICourierStatisticResponse[]).map(({id}) => id))
        ).map(Number);
    },

    _calculateOrdersStatusesPercents(ordersCountByOrderStatus: Record<OrderStatus, number>, closingOrdersCount: number) {
        return Object.entries(ordersCountByOrderStatus)
            .reduce((accum, [orderStatus, ordersCount]) => {
                const percent = ordersCount * 100 / closingOrdersCount;

                return {
                    ...accum,
                    [orderStatus]: {
                        count: ordersCount,
                        // todo: duplicate
                        percent: closingOrdersCount === 0
                            ? 0
                            : percent > 1
                                ? Math.round(percent)
                                : Number(percent.toFixed(1)),
                    },
                };
            }, {});
    },

    _calculateOrdersDeliveredOnTimePercents(data: IDeliveredBeforeOrAfterOnTime[], closingOrdersCount: number) {
        if (closingOrdersCount === 0) {
            return {
                count: 0,
                percent: 0,
            }
        }

        const ordersIdsDeliveredOnTimeCount = closingOrdersCount - data.length;
        const percent = ordersIdsDeliveredOnTimeCount * 100 / closingOrdersCount;

        return {
            count: ordersIdsDeliveredOnTimeCount,
            // todo: duplicate
            percent: percent > 1 ? Math.round(percent) : Number(percent.toFixed(1)),
        };
    },

    _calculateOrdersNotDeliveredOnTimePercents(data: IDeliveredBeforeOrAfterOnTime[], closingOrdersCount: number) {
        if (closingOrdersCount === 0) {
            return {
                count: 0,
                percent: 0,
            }
        }

        const ordersCount = data.length;
        const percent = ordersCount * 100 / closingOrdersCount;

        return {
            count: ordersCount,
            // todo: duplicate
            percent: percent > 1 ? Math.round(percent) : Number(percent.toFixed(1)),
        };
    },

    _calculateOrdersDeliveredTimeDetailPercents(data: IDeliveredBeforeOrAfterOnTime[], closingOrdersCount: number) {
        const upTo15MinCount = data.filter(({diffMs}) => diffMs <= MINUTE_MS * 15).length;
        const upTo30MinCount = data.filter(({diffMs}) => diffMs > MINUTE_MS * 15 && diffMs <= MINUTE_MS * 30).length;
        const over30MinutesCount = data.filter(({diffMs}) => diffMs > MINUTE_MS * 30).length;

        const upTo15MinPercent = upTo15MinCount * 100 / closingOrdersCount;
        const upTo30MinPercent = upTo30MinCount * 100 / closingOrdersCount;
        const over30MinutesPercent = over30MinutesCount * 100 / closingOrdersCount;

        return {
            upTo15Min: {
                count: upTo15MinCount,
                // todo: duplicate
                percent: upTo15MinPercent > 1 ? Math.round(upTo15MinPercent) : Number(upTo15MinPercent.toFixed(1)),
            },
            upTo30Min: {
                count: upTo30MinCount,
                // todo: duplicate
                percent: upTo30MinPercent > 1 ? Math.round(upTo30MinPercent) : Number(upTo30MinPercent.toFixed(1)),
            },
            over30Minutes: {
                count: over30MinutesCount,
                // todo: duplicate
                percent: over30MinutesPercent > 1 ? Math.round(over30MinutesPercent) : Number(over30MinutesPercent.toFixed(1)),
            },
        };
    },
};

export default function(state = initialState, {payload = {}, type}: IStatisticAction): IStatisticReducer {
    if (!type) {
        return state;
    }

    let patch: any;

    switch (type) {
        case StatisticActions.BalanceGet:
            patch = {
                balance: {
                    $set: payload.balance,
                }
            };
            break;

        case StatisticActions.DebtGet:
            patch = {
                debt: {
                    $set: payload.debt,
                }
            };
            break;

        case StatisticActions.CustomersGet:
            patch = {
                customers: {
                    $set: payload.customers,
                }
            };
            break;

        case StatisticActions.CouriersGet:
            patch = {
                couriers: {
                    $set: payload.couriers,
                }
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
