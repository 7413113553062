import {
    Action,
} from 'redux';

import {
    IPayment,
    PaymentActions,
} from 'app/types/payment';
import {
    IHistory,
} from 'app/types/history';

import {
    stringToDate,
} from 'app/libs/date';
import {
    applyPatch,
} from 'app/libs/structure';
import {
    mergeEntities,
} from 'app/libs/utils';

interface IData {
    payments: IPayment[];
    history: IHistory[];
}

export type IPaymentReducer = {
    _data: IData;
    getPayment: (id: number) => IPayment;
    getPayments: () => IPayment[];
    getHistory: () => IHistory[];
};

export interface IPaymentAction extends Action<PaymentActions> {
    payload?: {
        payments?: IPayment[];
        history?: IHistory[];
    };
}

const initialPayments: [] = [];
const initialHistory: [] = [];

const initialState: IPaymentReducer = {
    _data: {
        payments: initialPayments,
        history: initialHistory,
    } as IData,

    getPayment(id: number) {
        return this._data.payments.find((payment: IPayment) => payment.id === id);
    },

    getPayments() {
        return this._data.payments;
    },

    getHistory() {
        return this._data.history;
    },
};

export default function(state = initialState, {payload = {}, type}: IPaymentAction): IPaymentReducer {
    if (!type) {
        return state;
    }

    const currentState = {...state};
    let patch: any;

    switch (type) {
        case PaymentActions.Create:
        case PaymentActions.Update:
        case PaymentActions.Get:
            patch = {
                payments: {
                    $set: mergeEntities(currentState.getPayments(), payload.payments)
                        .sort((a, b) => stringToDate(b.day).getTime() - stringToDate(a.day).getTime()),
                }
            };
            break;

        case PaymentActions.Clear:
            patch = {
                payments: {
                    $set: initialPayments,
                }
            };
            break;

        case PaymentActions.GetHistory:
            patch = {
                history: {
                    $set: mergeEntities(currentState.getHistory(), payload.history),
                }
            };
            break;

        case PaymentActions.ClearHistory:
            patch = {
                history: {
                    $set: initialHistory,
                }
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
