import {
    RouterState,
} from 'connected-react-router';

import {
    combineReducers,
} from 'redux'
import {
    connectRouter,
} from 'connected-react-router';

import users, {
    IUserReducer,
} from 'app/reducers/user';
import orders, {
    IOrderReducer,
} from 'app/reducers/order';
import payments, {
    IPaymentReducer,
} from 'app/reducers/payment';
import customPrices, {
    ICustomPricesReducer,
} from 'app/reducers/customPrices';
import alerts, {
    IAlertReducer,
} from 'app/reducers/alerts';
import statistics, {
    IStatisticReducer,
} from 'app/reducers/analytics';

export interface IReduxState {
    routing: RouterState;
    users: IUserReducer;
    orders: IOrderReducer;
    payments: IPaymentReducer;
    customPrices: ICustomPricesReducer;
    alerts: IAlertReducer;
    statistics: IStatisticReducer;
}

export default (history: any) => combineReducers({
    router: connectRouter(history),
    users,
    orders,
    payments,
    customPrices,
    alerts,
    statistics,
});
