import {
    IBaseGetParams,
} from 'app/types/fetch';

import constants from 'app/constants.json';

export interface IPayment {
    id?: number;
    userId?: number;
    day?: string;
    transferredByUserId: number;
    typeEnum?: PaymentType;
    createdByUserId?: number;
    createdTimestamp?: string;
    isRemoved?: boolean;
    virtual?: {
        totalCashOnDelivery: number;
        totalDeliveryCost: number;
        totalInsuranceFee: number;
        estimatedAmount: number;
        status: string;
        reconciliation: number;
    };
}

export enum PaymentActions {
    Create = 'payment_create',
    Update = 'payment_update',
    Get = 'payment_get',
    Clear = 'payment_clear',
    GetHistory = 'get_payment_history',
    ClearHistory = 'clear_payment_history',
}

export enum PaymentType {
    // расчеты курьеры
    CourierIncome = constants.paymentTypeEnum.courierIncome,
    // ЗП курьеры
    CourierExpenditure = constants.paymentTypeEnum.courierExpenditure,
    CustomerCalculations = constants.paymentTypeEnum.customerIncome,
}

export const paymentTypeOptions = [
    {
        value: PaymentType.CourierIncome,
        name: 'Курьер приход', // наложенных платежей
    },
    {
        value: PaymentType.CourierExpenditure,
        name: 'Курьер ЗП',
    },
    {
        value: PaymentType.CustomerCalculations,
        name: 'Расчеты заказчик',
    },
];

export enum PaymentStatus {
    OrdersNotFound = 'ordersNotFound',
    ReportsNotGenerated = 'reportsNotGenerated',
    WaitTransfer = 'waitTransfer',
    ToPay = 'toPay',
    MoneyReceived = 'moneyReceived',
}

export const paymentStatusOptions = [
    {
        value: PaymentStatus.OrdersNotFound,
        name: 'Заказов не найдено',
    },
    {
        value: PaymentStatus.ReportsNotGenerated,
        name: 'Отчеты не сформированы',
    },
    {
        value: PaymentStatus.WaitTransfer,
        name: 'Ждем перевод',
    },
    {
        value: PaymentStatus.ToPay,
        name: 'К оплате',
    },
    {
        value: PaymentStatus.MoneyReceived,
        name: 'Деньги получены',
    },
];

export interface IPaymentGetParams extends IBaseGetParams {
    userIds?: number[];
    paymentDayFrom?: string;
    paymentDayTo?: string;
    typesEnum?: number[];
    userTypesEnum?: number[];
    virtualStatuses?: PaymentStatus[];
}
