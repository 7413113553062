import min from 'date-fns/min';
import max from 'date-fns/max';
import parseISO from 'date-fns/parseISO';
import isDate from 'date-fns/isDate';

// date -> string yyyy-MM-dd HH:mm:ss
export function dateToString(date: Date) {
    if (!date) {
        return null;
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const addLeadingZero = (value: number) => value < 10 ? `0${value}` : value;

    return `${year}-${addLeadingZero(month)}-${addLeadingZero(day)} ${addLeadingZero(hour)}:${addLeadingZero(minute)}:${addLeadingZero(second)}`;
}

// yyyy-MM-dd HH:mm:ss -> date
export function stringToDate(date: string) {
    return parseISO(date);
}

/**
 * 2019-07-24 16:43:40.500406 -> 2019-07-24 16:43
 */
export function transformBackendTimestampToFrontend(date: string) {
    return (date || '').replace(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}):\d{2}.\d+/, '$1');
}

// todo: rename and think about duplicate code with bot repo
/**
 * yyyy-MM-dd -> yyyy-MM-dd HH:mm:ss
 * yyyy-MM-dd HH:mm -> yyyy-MM-dd HH:mm:ss
 */
export function transformBotDateToBackend(date: string) {
    if (typeof date !== 'string') {
        return date;
    }
    return (date || '')
        .replace(/^(\d{4}-\d{2}-\d{2})$/, '$1' + ' 00:00:00')
        .replace(/^(\d{4}-\d{2}-\d{2} \d{2}:\d{2})$/, '$1' + ':00');
}

export function isSameDate(date1: Date, date2: Date) {
    if (!isDate(date1) || !isDate(date2)) {
        return !isDate(date1) && !isDate(date2);
    }

    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function getMinDate(dates: Array<Date | string>) {
    const normalizedDates = dates.filter(Boolean).map(date => typeof date === 'string' ? stringToDate(date) : date);
    if (!normalizedDates.length) {
        return null;
    }
    return min(normalizedDates);
}

export function getMaxDate(dates: Array<Date | string>) {
    const normalizedDates = dates.filter(Boolean).map(date => typeof date === 'string' ? stringToDate(date) : date);
    if (!normalizedDates.length) {
        return null;
    }
    return max(normalizedDates);
}

/**
 * 2019-07-24 16:43:40.500406 -> 2019-07-24
 */
export function transformBackendTimestampToDate(date: string) {
    return (date || '').replace(/(\d{4}-\d{2}-\d{2}).*/, '$1');
}

export function setMaxTimeToDate(date: Date) {
    const dateCopy = new Date(date);

    dateCopy.setHours(23);
    dateCopy.setMinutes(59);
    dateCopy.setSeconds(59);
    dateCopy.setMilliseconds(999);

    return dateCopy;
}

export function setMinTimeToDate(date: Date) {
    const dateCopy = new Date(date);

    dateCopy.setHours(0);
    dateCopy.setMinutes(0);
    dateCopy.setSeconds(0);
    dateCopy.setMilliseconds(0);

    return dateCopy;
}

export function isDateFormatIsDay(date: string = '') {
    return /^(\d{4}-\d{2}-\d{2})$/.test(date);
}
