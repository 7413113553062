import {
    Dispatch,
} from 'redux';

import {
    IAlert,
    AlertActions,
} from 'app/types/alerts';
import {
    IAlertAction,
} from 'app/reducers/alerts';

import gererateUniqId from 'uniqid';

export function addAlert(alert: IAlert) {
    return (dispatch: Dispatch<IAlertAction>) => {
        const id = gererateUniqId();
        dispatch({
            type: AlertActions.Add,
            payload: {
                alert: {
                    id,
                    ...alert,
                },
            },
        });
        return id;
    };
}

export function removeAlert(id: string) {
    return (dispatch: Dispatch<IAlertAction>) => {
        return dispatch({
            type: AlertActions.Remove,
            payload: {
                id,
            },
        });
    };
}