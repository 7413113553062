import {
    AxiosResponse,
    AxiosError,
} from 'axios';

import {
    IReduxState,
} from 'app/reducers';
import {
    IFetchOptions,
} from 'app/types/fetch';

const {
    NODE_ENV,
} = process.env;

const disableLoggers = NODE_ENV !== 'development';
const disableFetchLoggers = false;
const disableStorePatchLoggers = true;
const disableStoreStateLoggers = true;

export function reduxLoggerPatch(type: string, patch: any) {
    if (disableLoggers || disableStorePatchLoggers) {
        return;
    }
    console.log(`%caction: ${type.toUpperCase()}`, 'color: #a99cc8; font-weight:bold; font-size: 16px;');
    console.log('%cpatch: ', 'color: #3355ff; font-weight:bold; font-size: 16px;', patch);
}

export function reduxLoggerState(prevState: IReduxState, newState: IReduxState) {
    if (disableLoggers || disableStoreStateLoggers) {
        return;
    }
    console.log('%cprevState: ', 'color: #ff9f33; font-weight:bold; font-size: 16px;', prevState);
    console.log('%cnewState: ', 'color: #ff9f33; font-weight:bold; font-size: 16px;', newState);
}

export function fetchLoggerProcess(options: IFetchOptions) {
    if (disableLoggers || disableFetchLoggers) {
        return;
    }
    const baseLog = `%c${options.method} start request ${options.url}`;
    const logStyle = 'color: #12C9DC; font-weight:bold;';
    if (options.method === 'GET') {
        if (options.params) {
            console.log(baseLog, logStyle, options.params);
        } else {
            console.log(baseLog, logStyle);
        }
    } else if (options.method === 'POST' || options.method === 'PATCH') {
        if (options.body) {
            console.log(baseLog, logStyle, options.body);
        } else {
            console.log(baseLog, logStyle);
        }
    } else if (options.method === 'PUT') {
        console.log(baseLog, logStyle);
    }
}

export function fetchLoggerSuccess(options: IFetchOptions, response: AxiosResponse) {
    if (disableLoggers || disableFetchLoggers) {
        return;
    }
    console.log(`%c${options.method} ${options.url} ${response.status}`, 'color: #3ABD32; font-weight:bold;', response.data);
}

export function fetchLoggerFailed(options: IFetchOptions, error: AxiosError) {
    if (disableLoggers || disableFetchLoggers) {
        return;
    }
    console.log(`%c${options.method} ${options.url} ${error.response.status}`, 'color: #DC1212; font-weight:bold;', error.response.data);
}
