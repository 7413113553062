import {
    IBaseGetParams,
} from 'app/types/fetch';

import constants from 'app/constants.json';

export interface IUser {
    id?: number;
    typeEnum?: UserType;
    familyName?: string;
    firstName?: string;
    email?: string;
    phone?: number;
    subway?: string;
    address?: string;
    login?: string;
    password?: string;
    apiKey?: string;
    isActive?: boolean;
    createdTimestamp?: string;
    virtual?: {
        hasAccess: boolean;
    };
}

export enum UserType {
    CourierWalking = constants.userTypesEnum.courier.walking,
    CourierCar = constants.userTypesEnum.courier.car,
    CustomerPerson = constants.userTypesEnum.customer.person,
    CustomerLegal = constants.userTypesEnum.customer.legal,
    WorkerSkdManager = constants.userTypesEnum.workerSkd.manager,
    WorkerSkdSeller = constants.userTypesEnum.workerSkd.seller,
    WorkerSkdLogist = constants.userTypesEnum.workerSkd.logist,
    WorkerSkdAccountant = constants.userTypesEnum.workerSkd.accountant,
    WorkerSkdAdmin = constants.userTypesEnum.workerSkd.admin,
}

export const userTypeOptions = [
    {
        value: UserType.CourierWalking,
        name: 'Пеший курьер',
    },
    {
        value: UserType.CourierCar,
        name: 'Авто-курьер',
    },
    {
        value: UserType.CustomerPerson,
        name: 'Заказчик (частное лицо)',
    },
    {
        value: UserType.CustomerLegal,
        name: 'Заказчик (юридическое лицо)',
    },
    {
        value: UserType.WorkerSkdManager,
        name: 'Менеджер',
    },
    {
        value: UserType.WorkerSkdSeller,
        name: 'Продавец',
    },
    {
        value: UserType.WorkerSkdLogist,
        name: 'Логист',
    },
    {
        value: UserType.WorkerSkdAccountant,
        name: 'Бухгалтер',
    },
    {
        value: UserType.WorkerSkdAdmin,
        name: 'Админ',
    },
];

export enum UserActions {
    LogIn = 'user_log_in',
    SignUp = 'user_sign_up',
    RegenerateApiKey = 'user_regenerate_api_key',
    LogOut = 'user_log_out',
    Get = 'user_get',
    Update = 'user_update',
    GetCurrent = 'user_get_current',
    SetCurrentUserCustomization = 'user_set_current_customization',
    Clear = 'user_clear',
    GetHistory = 'get_user_history',
    ClearHistory = 'clear_user_history',
    SetVirtualFields = 'user_set_virtual_fields',
}

export interface IUserGetParams extends IBaseGetParams {
    familyNames?: string[];
    phones?: number[];
    emails?: string[];
    logins?: string[];
    typesEnum?: UserType[];
    active?: boolean;
}
