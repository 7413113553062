import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'app/components/Loading/Loading';

// @ts-ignore
export default Loadable({
    loader: () => import(/*webpackChunkName: 'Api'*/'app/controllers/Api/Api')
        .catch(() => new Error('An error occurred while loading the TextArea')),
    loading: Loading,
});
