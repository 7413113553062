import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'app/components/Loading/Loading';

// @ts-ignore
export default Loadable({
    loader: () => import(/*webpackChunkName: 'Payments'*/'app/controllers/Payments/Payments')
        .catch(() => new Error('An error occurred while loading the Payments')),
    loading: Loading,
});
